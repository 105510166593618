import React from 'react'
import { Button, Link, Box, Typography, styled, useMediaQuery } from '@mui/material'
import { NavLink as NavLinkBase, type NavLinkProps } from 'react-router-dom'
import ProfileMenu from './ProfileMenu'
// import { useAuthContext } from '../../contexts'
import { useTheme } from '@mui/material/styles'
import { log } from 'console'

const StyledNavLink = styled(NavLinkBase)<NavLinkProps>(({ theme }) => ({
  '&.active': {
    fontWeight: 500,
    color: theme.palette.primary.main,
  },
}))

const NavLinks = () => {
  // const { isAuthenticated } = useAuthContext()
  const token = sessionStorage.getItem('token')
  const isPaid: boolean = sessionStorage.getItem('isPaid') === 'true'
  // console.log('isPaid', isPaid)
  const theme = useTheme()
  const smMatched = useMediaQuery(theme.breakpoints.down('sm'))
  const mdMatched = useMediaQuery(theme.breakpoints.up('md'))

  // console.log('smMatched', smMatched)
  // console.log('mdMatched', mdMatched)

  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: smMatched ? 'column' : 'row',
        alignItems: 'center',
        justifyContent: 'flex-end',
      }}
      gap={3.5}
    >
      {token == null && (
        <Link component={StyledNavLink} to='/' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Home</Typography>
        </Link>
      )}

      {token !== null && (
        <Link component={StyledNavLink} to='/newChat' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Home</Typography>
        </Link>
      )}

      {isPaid && token !== null && (
        <Link component={StyledNavLink} to='/verifier' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Verifier</Typography>
        </Link>
      )}

      {isPaid && mdMatched && token !== null && (
        <Link component={StyledNavLink} to='/editor' underline='none' color='text.contrastText'>
          <Typography variant='body2'>Editor</Typography>
        </Link>
      )}

      <Link component={StyledNavLink} to='/contact-us' underline='none' color='text.contrastText'>
        <Typography variant='body2'>Contact Us</Typography>
      </Link>

      <Link
        component={StyledNavLink}
        to='https://www.lawtech.pro/blogs'
        target='_blank'
        underline='none'
        color='text.contrastText'
      >
        <Typography variant='body2'>Blog</Typography>
      </Link>
      {/* {!isAuthenticated && (
        <Button
          component={NavLinkBase}
          to='/auth/login'
          variant='contained'
          sx={{ width: 120, py: 1.5, px: 2.3 }}
        >
          Login
        </Button>
      )} */}
      {token == null && (
        <Button
          component={NavLinkBase}
          to='/auth/login'
          variant='contained'
          sx={{ width: 120, py: 1.5, px: 2.3 }}
        >
          Login
        </Button>
      )}
      {token && <ProfileMenu />}
    </Box>
  )
}

export default NavLinks
