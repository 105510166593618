import * as React from 'react'
import { Box, Button, Stack } from '@mui/material'
import offerImage from '../../assets/images/offerImage.jpeg'
// import Header from '../../components/Header'
import Footer from '../../components/Footer'
import ContactSection from './ContactUs'
import WhyChooseUsSection from './WhyChooseUs'
// import OurClientsSection from './OurClients'
import HowItWorksSection from './HowItWorks'
import MainSection from './MainSection'

const HomeIndex = () => {
  // console.log('home Index')
  const currentDate = new Date()
  const targetDate = new Date('2024-12-31')
  const [isVisible, setIsVisible] = React.useState(false)
  const [remindMeLater, setRemindMeLater] = React.useState(false)

    // new year offer popup
  React.useEffect(() => {
    // Check sessionStorage to see if the popup should be hidden
    const popupClosed = sessionStorage.getItem('popupClosed');
    if (!popupClosed) {
      const showPopup = () => {
        setIsVisible(true);
        setTimeout(() => {
          setIsVisible(false);
        }, 15000); // Popup stays for 15 seconds
      };

      // Show popup after 30 seconds initially
      const initialTimer = setTimeout(() => {
        showPopup();
        // Start the interval only after the first popup is shown and hidden
        const intervalId = setInterval(showPopup, 45000); // 30 seconds wait + 15 seconds display time
        // Clear the interval when the component unmounts
        return () => {clearInterval(intervalId)}
      }, 10000);
  
      // Clear the initial timer when the component unmounts
      return () => {
        clearTimeout(initialTimer);
      }
    }
  }, []);

  // new year popup close
  const handleRemindMeLater = () => {
    setRemindMeLater(true);
    setIsVisible(false);
    sessionStorage.setItem('popupClosed', 'true'); // Set popupClosed in sessionStorage
  };

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 7.5, mt: 12 }}>
        {/* <Header maxWidth='false' /> */}
        <MainSection />
        <HowItWorksSection />
        {/* <OurClientsSection /> */}
        <WhyChooseUsSection />
        <ContactSection />
        <Footer />
      </Box>
      {isVisible && !remindMeLater && currentDate <= targetDate && (
        <Box
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'fixed',
            left: '50%',
            top: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '10003',
          }}
          // onClick={handleOfferPlan}
        >
          <img src={offerImage} width='50%' alt='Offer valid till 31st Dec 24' />
          <Stack
            sx={{
              width: '50%',
              bgcolor: '#777',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{ width: '400px', padding: '10px 0px' }}
              onClick={handleRemindMeLater}
            >
              Remind me later
            </Button>
          </Stack>
        </Box>
      )}
    </>
  )
}

export default HomeIndex