import React, { useState, useEffect } from 'react'
import { Box, Button, Card, CardMedia, IconButton, Stack, styled, Typography,Dialog, DialogContent, DialogTitle, } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'
import { useSidebarContext } from '../../contexts/Old_SidebarContext'
import { useAuthContext } from '../../contexts/AuthContext'
import { DRAWER_WIDTH } from '../ChatBoat/constants'
import UpgradeSubscriptionDialog from '../../components/shared/Dialogs/UpgradeSubscription'
import Sidebar from '../ChatBoat/LeftSidebar/Actions/Sidebar'
import CloseIcon from '@mui/icons-material/Close'
import { useSnackbar } from '../../components/SnackbarProvider'
import NewMessage from '../ChatBoat/MainContent/NewMessage'

import SendMessage from '../ChatBoat/MainContent/SendMessage'

import Stepper from '../ChatBoat/MainContent/Messages/Actions/Stepper'
import baseUrl from '../../config/baseUrl'
import offerImage from '../../assets/images/offerImage.jpeg'

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })<{
  open?: any
}>(({ theme, open }) => ({
  display: 'flex',
  flexGrow: 1,
  transition: theme.transitions.create('margin', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),

  padding: theme.spacing(2, 4, 2, 2),
  marginLeft: `-${DRAWER_WIDTH}px`,
  ...(open && {
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginLeft: theme.spacing(2),
  }),
}))

const MainContent = () => {
  const loginCount = Number(sessionStorage.getItem('loginCount') ?? 0)
  const isPaid: boolean = sessionStorage.getItem('isPaid') === 'true'
  const [openUpgradeSubscriptionDialog, setOpenUpgradeSubscriptionDialog] = useState(false)
  // alert('newchat')
  const {
    upgradeSubscriptionDialog,
    setUpgradeSubscriptionDialog,
    isLoading,
    sidebarOpen,
    allHistory,
    aiStorage,
    setAiStorage,
    setInputMessage,
    setAnswer,
    setThreadId,
    // setIsThreadIdDelete,
    // isThredIdDelete,
    threadId,
  } = useSidebarContext()

  const showSnackbar = useSnackbar()
  const navigate = useNavigate()
  const token = sessionStorage.getItem('token')
  const ExpiryDate = sessionStorage.getItem('ExpiryDate')

  const param = useParams()
  const { id } = param
  const storedValue = sessionStorage.getItem('userId')
  const userId: number = sessionStorage.getItem('userId') as unknown as number
  const parsedNumber = storedValue ? parseInt(storedValue, 10) : null
  const { setAuthenticated } = useAuthContext()
  const [mergedData, setMergedData] = useState<any>([])
  const [subSearchActiveSteps, setSubSearchActiveSteps] = useState<any>(allHistory.map(() => 0))
  const [remindMeLater, setRemindMeLater] = useState(false)
  const [isVisible, setIsVisible] = useState(false)
  const [isNewYearOfferVisible, setIsNewYearOfferVisible] = useState(false)
  const [planExpiryDays, setPlanExpiryDays] = useState<number | null>(null);
  const [isExpiring, setIsExpiring] = useState<boolean>(false);
  const [okGotIt, setOkGotIt] = useState<boolean>(false);

  const [openOfferDialog, setOpenOfferDialog] = useState(false)

  const currentDate = new Date()
  const targetDate = new Date('2024-12-31')

  useEffect(() => {
    document.title = 'New Chat'
    // setInputMessage((prevMsg) => (prevMsg = ''))
    // Make initial API request when storedNumber changes

    userId && fetchData(userId)

    // for offer plan
    let timer: any
    setInterval(() => {
      // console.log('setting isvisible to true')
      setIsVisible(true)
      timer = setTimeout(() => {
        setIsVisible(false)
      }, 15000)
    }, 30000)

    // Set up an interval to call fetchData every 1 minute
    const intervalId = setInterval(() => {
      if (userId) {
        fetchData(userId)
        checkToken()
      }
    }, 60000) // 60000 milliseconds = 1 minute

    // Clean up the interval when the component is unmounted
    return () => {
      clearInterval(intervalId)
      clearTimeout(timer)
    }
  }, [])

  // expiry popup
  useEffect(() => {
    // Check if the dialog has already been dismissed
    const dismissed = sessionStorage.getItem('okGotIt') === 'true';
    setOkGotIt(dismissed);

    if (ExpiryDate && !dismissed) {
      const expiryDate = new Date(ExpiryDate);
      const currentDate = new Date();
      const timeDiff = expiryDate.getTime() - currentDate.getTime();

      // Calculate the number of days remaining
      const daysRemaining = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

      // Set state based on the remaining days
      if (daysRemaining >= 1 && daysRemaining <= 3) {
        setPlanExpiryDays(daysRemaining);
        setIsExpiring(true);
      } else {
        setIsExpiring(false);
      }
    }
  }, [ExpiryDate]);

  // new year offer popup
    React.useEffect(() => {
        // Check sessionStorage to see if the popup should be hidden
        const popupClosed = sessionStorage.getItem('hideOffer');
        if (!popupClosed) {
          const showPopup = () => {
            setIsNewYearOfferVisible(true);
            setTimeout(() => {
              setIsNewYearOfferVisible(false);
            }, 15000); // Popup stays for 15 seconds
          };
    
          // Show popup after 30 seconds initially
          const initialTimer = setTimeout(() => {
            showPopup();
            // Start the interval only after the first popup is shown and hidden
            const intervalId = setInterval(showPopup, 45000); // 30 seconds wait + 15 seconds display time
            // Clear the interval when the component unmounts
            return () => {clearInterval(intervalId)}
          }, 10000);
      
          // Clear the initial timer when the component unmounts
          return () => {
            clearTimeout(initialTimer);
          }
        }
      }, []);

      // new year popup close
      const handleRemindMeLater = () => {
        setRemindMeLater(true);
                setIsNewYearOfferVisible(false);
                sessionStorage.setItem('hideOffer', 'true');
      };

  useEffect(() => {
    const getUserHistory = async () => {
      try {
        const userHistoryRespo = await fetch(`${baseUrl}/api/users/gethistory/${userId}`, {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            authorization: token as string,
          },
        })

        const userHistoryData: any = await userHistoryRespo.json()

        // console.log('userHistoryData?.historydata', userHistoryData)
        setAiStorage(userHistoryData?.historydata)
      } catch (error: any) {
        // console.log('This Error occur in fetching history ', error.message)
        showSnackbar(error, 'error')
      }
    }
    // console.log('threadId', threadId)
    threadId && getUserHistory()
  }, [threadId])

  useEffect(() => {
    id && setThreadId(id)
  }, [id])

  useEffect(() => {
    !isPaid && setUpgradeSubscriptionDialog(true)
  }, [isPaid])

  // For Pagination :

  useEffect(() => {
    // console.log('id, allHistory', id, allHistory)
    const newArray = allHistory.map((obj: any) => {
      // console.log('obj inside allHistory', obj)
      if (obj.subSerach) {
        return {
          ...obj,
          subSerach: [obj, ...obj.subSerach], // Move the object into subSerach
        }
      } else {
        return obj
      }
    })
    // console.log('newArray', newArray)
    setMergedData(newArray)
  }, [id, allHistory])

  // Updated code for pagination 3

  useEffect(() => {
    setSubSearchActiveSteps(allHistory.map(() => 0))
  }, [allHistory])

  const handleOfferPlan = async (offerPlan: any) => {
    // // console.log('offerPlan', offerPlan)
    // try {
    //   const subscriptionResonse = await fetch(`${baseUrl}/api/order/create`, {
    //     method: 'POST',
    //     mode: 'cors',
    //     headers: {
    //       'Content-Type': 'application/json',
    //       authorization: token ?? '',
    //     },
    //     body: JSON.stringify({
    //       userid: userId,
    //       amount: 4999,
    //       // amount: offerPlan?.Price,
    //       subscriptionid: offerPlan?.id,
    //     }),
    //   })
    //   const subscriptionResonseData = await subscriptionResonse.json()
    //   // console.log('subscriptionResonseData', subscriptionResonseData)
    //   if (subscriptionResonse.ok) {
    //     window.open(subscriptionResonseData.payment_link, '_blank')
    //   }
    // } catch (error) {
    //   setSnackbarMessage('Error in upgrade subscription');
    //   setSnackbarSeverity('error');
    //   setSnackbarOpen(true);
    // }
  }

  const handleOkGotIt = () => {
    setOkGotIt(true);
    sessionStorage.setItem('okGotIt', 'true'); // Persist dismissal
  };

  const checkToken = async () => {
    try {
      const response = await fetch(`${baseUrl}/api/logintoken/chectoken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userid: userId,
          token,
        }),
      })

      if (!response?.ok) {
        throw Error(`${response?.statusText} - ${response?.url}`)
      }

      const data = await response.json()
      // console.log('data checktoken', data)
      if (data.status === false) {
        sessionStorage.clear()
        sessionStorage.clear()
        setAuthenticated(false)
        navigate('/auth/login', { replace: true })
      }
    } catch (error) {
      showSnackbar('something went wrong', 'error')
    }
  }
  const fetchData = async (number: number) => {
    try {
      const response = await fetch(`${baseUrl}/api/admin/userstatus/${number}`)

      const data = await response.json()
      if (response.ok) {
        // console.log('fetchData', data)
        if (data?.Blocked === true) {
          sessionStorage.clear()
          navigate('/auth/login')
        }else{
          navigate('/NewChat')
        }
        // console.log('fetchData data response', data)
        // Process the data as needed
      } else {
        showSnackbar(data?.message || 'Failed to get user status', 'error')
      }
    } catch (error: any) {
      showSnackbar(error?.message || 'Something went wrong', 'error')
    }
  }

  const handleSubSearchStepChange = (index: number, step: number) => {
    setSubSearchActiveSteps((prevActiveSteps: any) => {
      const newActiveSteps = [...prevActiveSteps]
      newActiveSteps[index] = step
      return newActiveSteps
    })
  }

  return (
    <>
      {isLoading && (
        <div
          style={{
            zIndex: 1400,
            position: 'absolute',
            // backgroundColor: 'rgba(202, 92, 92, 0.5)',
          }}
        >
          {/* <h3 style={{ color: 'green', position: 'fixed', top: '45%', left: '45%' }}>
            Generating response... newchat{' '}
          </h3> */}

          {/* <CircularProgress
            disableShrink
            color='primary'
            thickness={4}
            style={{ position: 'fixed', top: '50%', left: '50%' }}
          /> */}
        </div>
      )}

      {/* --------------------- Offer advertisement popup ------------------- */}
      {token !== null && isVisible && loginCount < 8 && (
        <Box
          className='popup-container'
          onClick={handleOfferPlan}
          sx={{
            left: { xs: '5%', sm: '30%' },
            width: { xs: '90%', sm: '400px' },
          }}
        >
          <Box
            style={{
              margin: '0 0 0 0',
              fontSize: '1.5rem',
              color: '#ff5722',
            }}
          >
            <div className='star-container'>
              <div className='star'></div>
            </div>
            <Box className='poppup-content'>
              <Box
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  boxSizing: 'border-box',
                  padding: 0,
                  margin: 0,
                  position: 'relative',
                }}
              >
                <h3 style={{ margin: 0, padding: 0, boxSizing: 'border-box', textAlign: 'center' }}>
                  Special Offer!
                </h3>
                <IconButton
                  onClick={() => {
                    setIsVisible(false)
                  }}
                >
                  <CloseIcon sx={{ fontSize: 20, color: 'red' }} />
                </IconButton>
              </Box>
              {/* <p style={{ margin: 0, padding: 0, boxSizing: 'border-box' }}>Check out this amazing deal. Don&apos;t miss out!</p> */}
              <h4 style={{ margin: 0, padding: 0, boxSizing: 'border-box', textAlign: 'center' }}>
                3 months at &#8377;4999 only
              </h4>
              <p
                style={{
                  margin: 0,
                  padding: 0,
                  boxSizing: 'border-box',
                  textAlign: 'right',
                  color: '#000',
                }}
              >
                Offer valid for next 7 days only
              </p>
            </Box>
          </Box>
        </Box>
      )}

      {/* --------------------- Offer advertisement popup ------------------- */}

      {token !== null && isNewYearOfferVisible && !remindMeLater && currentDate <= targetDate && (
        // <Box
        //   style={{
        //     display: 'flex',
        //     flexDirection: 'column',
        //     justifyContent: 'center',
        //     alignItems: 'center',
        //     position: 'fixed',
        //     left: '50%',
        //     top: '50%',
        //     transform: 'translate(-50%, -50%)',
        //     zIndex: '10003',
        //   }}
        //   // onClick={handleOfferPlan}
        // >
        <Dialog open={isNewYearOfferVisible} maxWidth='xs'>
          <img src={offerImage} width='100%' alt='Offer valid till 31st Dec 24' />
          <Stack
            sx={{
              width:'100%',
              bgcolor: '#777',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              style={{ width: '400px', padding: '10px 0px' }}
              onClick={handleRemindMeLater}
            >
              Remind me later
            </Button>
          </Stack>
        {/* </Box> */}
        </Dialog>
      )}
      {ExpiryDate && isExpiring && !okGotIt && (
        <Dialog
        open={true}
        onClose={handleOkGotIt}
        sx={{
          '& .MuiDialog-paper': {
            position: 'absolute',
            top: 85, // Adjust vertical position
            right: 16, // Adjust horizontal position
            m: 0, // No default margin
            width: '300px', // Adjust width as needed
            borderRadius: '8px',
            zIndex: '10003',
          },
        }}
      >
        <DialogTitle
          sx={{
            bgcolor: '#777',
            color: '#fff',
            textAlign: 'center',
            fontSize: '1.2rem',
            padding: '10px',
          }}
        >
          Subscription Alert
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '10px',
            bgcolor: '#f5f5f5',
            textAlign: 'center',
          }}
        >
          <Typography variant="body1">
            Your plan is going to expire in
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          <strong>{planExpiryDays} {planExpiryDays === 1 ? 'day' : 'days'}</strong>
          </Typography>
          <Typography variant="body1" sx={{ mb: 2 }}>
          Valid till {ExpiryDate?.split('T')[0]}
          </Typography>

          <Button
            variant="contained"
            size='small' className='btnStyle'
            onClick={handleOkGotIt}
            sx={{ width: '100%' }}
          >
            Ok
          </Button>
        </DialogContent>
      </Dialog>
      )}

      <Main
        sx={{
          position: 'relative',
          marginTop: '5.5rem',
          paddingRight: { xs: '16px', sm: '32px' },
          paddingLeft: { xs: '8px', sm: '16px' },
        }}
        open={sidebarOpen}
      >
        {!sidebarOpen && <Sidebar />}
        <Stack flexGrow={1} alignItems='center' position='relative'>
          <Stack flexGrow={1} spacing={2} width={1} maxWidth={1000}>
            <Stack flexGrow={1} spacing={2}>
              {/* <ActiveFilters /> */}

              {mergedData?.length === 0 && <NewMessage />}

              {mergedData?.map((each: any, eachIndex: number) => (
                <div key={each.id}>
                  <Stepper
                    subSearchArray={each.subSerach || []}
                    activeStep={subSearchActiveSteps[eachIndex]}
                    handleStepChange={(step: number) => {
                      handleSubSearchStepChange(eachIndex, step)
                    }}
                  />
                </div>
              ))}
            </Stack>
            {upgradeSubscriptionDialog && <UpgradeSubscriptionDialog />}
            <div style={{ position: 'relative' }}>
              {!mergedData?.length && (
                <p>
                  The provided information outlines the required experience, skill sets, and
                  responsibilities for a position related to designing and delivering UI solutions
                  for network operational processes. Below is a breakdown of the qualifications and
                  skills needed for this role:
                </p>
              )}
            </div>

            <SendMessage
              setInputMessage={setInputMessage}
              setAnswer={setAnswer}
              aiStorage={aiStorage}
              setAiStorage={setAiStorage}
            />
          </Stack>
        </Stack>
      </Main>
    </>
  )
}

export default MainContent
