import React, { useRef, useState, FormEvent, useEffect } from 'react'
import baseUrl from '../../../config/baseUrl'
import {
  Grid,
  Link,
  TextField,
  MenuItem,
  IconButton,
  InputAdornment,
  Button,
  Box,
  Typography,
  Checkbox,
  CircularProgress,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'
import { Link as RouteLink } from 'react-router-dom'
import { useSnackbar } from '../../../components/SnackbarProvider'
import { useSidebarContext } from '../../../contexts/Old_SidebarContext'

const states = [
  'Andaman and Nicobar Islands',
  'Andhra Pradesh',
  'Arunachal Pradesh',
  'Assam',
  'Bihar',
  'Chandigarh',
  'Chhattisgarh',
  'Dadra and Nagar Haveli and Daman and Diu',
  'Delhi',
  'Goa',
  'Gujarat',
  'Haryana',
  'Himachal Pradesh',
  'Jammu and Kashmir',
  'Jharkhand',
  'Karnataka',
  'Kerala',
  'Ladakh',
  'Lakshadweep',
  'Madhya Pradesh',
  'Maharashtra',
  'Manipur',
  'Meghalaya',
  'Mizoram',
  'Nagaland',
  'Odisha',
  'Punjab',
  'Puducherry',
  'Rajasthan',
  'Sikkim',
  'Tamil Nadu',
  'Telangana',
  'Tripura',
  'Uttarakhand',
  'Uttar Pradesh',
  'West Bengal',
]

interface Props {
  tcAccepted: boolean
  setTcAccepted: any
  // setStep: any
  setBackotp: any
  setRegistrationDetails: any
  registrationDetail: any
}

const RegistrationForm: React.FC<Props> = ({
  tcAccepted,
  setTcAccepted,
  setBackotp,
  setRegistrationDetails,
  registrationDetail,
}: Props) => {
  const firstNameRef = useRef<HTMLInputElement>(null)
  const lastNameRef = useRef<HTMLInputElement>(null)
  const contactNoRef = useRef<HTMLInputElement>(null)
  const emailRef = useRef<HTMLInputElement>(null)
  const stateRef = useRef<HTMLInputElement>(null)
  const cityRef = useRef<HTMLInputElement>(null)
  const passwordRef = useRef<HTMLInputElement>(null)
  const confirmPasswordRef = useRef<HTMLInputElement>(null)

  const values = {
    firstName: '',
    lastName: '',
    contactNo: '',
    email: '',
    state: '',
    city: '',
    password: '',
    confirmPassword: '',
  }

  const { setBackEndOtp, activeStep, setActiveStep } = useSidebarContext()

  const label = { inputProps: { 'aria-label': 'Checkbox demo' } }
  const [termsCheckbox, setTermsCheckbox] = useState(false)
  const [loading, setLoading] = useState(false)

  const [Registration, setRegistration] = useState<any>(values)

  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [errors, setErrors] = useState<Record<string, string>>({})
  const showSnackbar = useSnackbar()
  const alphabetRegex = /^[A-Za-z]+$/

  useEffect(() => {
    document.title = 'Register - LawTech'
    const fName = sessionStorage.getItem('firstName')
    const lName = sessionStorage.getItem('lastName')
    const email = sessionStorage.getItem('email')
    const contactNo = sessionStorage.getItem('contactNo')
    const state = sessionStorage.getItem('state')
    const city = sessionStorage.getItem('city')
    // console.log(
    //   'fName, lName, email, contactNo, state, city',
    //   fName,
    //   lName,
    //   email,
    //   contactNo,
    //   state,
    //   city,
    // )

    if (fName !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      firstNameRef.current!.value = fName
      sessionStorage.setItem('firstName', fName)
    }
    if (lName !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      lastNameRef.current!.value = lName
      sessionStorage.setItem('lastName', lName)
    }
    if (email !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      emailRef.current!.value = email
      sessionStorage.setItem('email', email.toLowerCase())
    }
    if (contactNo !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      contactNoRef.current!.value = contactNo
      sessionStorage.setItem('contactNo', contactNo)
    }
    if (state !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      stateRef.current!.value = state
      sessionStorage.setItem('state', state)
    }
    if (city !== null) {
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      cityRef.current!.value = city
      sessionStorage.setItem('city', city)
    }
    setTimeout(() => {
      sessionStorage.clear()
    }, 1000)
  }, [])

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev)
  }

  const validateForm = (): boolean => {
    const formErrors: Record<string, string> = {}

    const firstName = firstNameRef.current?.value.trim()
    if (!firstName || !alphabetRegex.test(firstName)) {
      formErrors.firstName = 'First Name is required and should contain only alphabets.'
    }

    const lastName = lastNameRef.current?.value.trim()
    if (!lastName || !alphabetRegex.test(lastName)) {
      formErrors.lastName = 'Last Name is required and should contain only alphabets.'
    }

    const contactNo = contactNoRef.current?.value.trim()
    if (!contactNo || !/^\d{10}$/.test(contactNo)) {
      formErrors.contactNo = 'Contact No. must be a 10-digit number only.'
    }

    const email = emailRef.current?.value.trim()
    if (!email || !/^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i.test(email)) {
      formErrors.email = 'Enter a valid email address.'
    }

    if (!stateRef.current?.value) {
      formErrors.state = 'State is required.'
    }

    const city = cityRef.current?.value.trim()
    if (!city || !alphabetRegex.test(city)) {
      formErrors.city = 'City Name is required and should contain only alphabets.'
    }

    const password = passwordRef.current?.value.trim()
    if (!password || password.length < 8 || password.length > 32) {
      formErrors.password = 'Password must be 8 to 32 characters long.'
    }

    const confirmPassword = confirmPasswordRef.current?.value
    if (confirmPassword !== password) {
      formErrors.confirmPassword = 'Passwords do not match.'
    }

    setErrors(formErrors)
    return Object.keys(formErrors).length === 0
  }

  const handleTermsCheckbox = () => {
    setTcAccepted(!tcAccepted)
    setTermsCheckbox(!termsCheckbox)
  }

  const handleChange = (e: any) => {
    const { name, value } = e.target
    // console.log('name, value', name, value)
    if (name === 'firstName') {
      sessionStorage.setItem('firstName', value)
    }
    if (name === 'lastName') {
      sessionStorage.setItem('lastName', value)
    }
    if (name === 'contactNo') {
      sessionStorage.setItem('contactNo', value)
    }
    if (name === 'email') {
      sessionStorage.setItem('email', value.toLowerCase())
    }
    if (name === 'state') {
      sessionStorage.setItem('state', value)
    }
    if (name === 'city') {
      sessionStorage.setItem('city', value)
    }
    if (name === 'password') {
      sessionStorage.setItem('password', value)
    }
    if (name === 'confirmPassword') {
      sessionStorage.setItem('confirmPassword', value)
    }

    setRegistration({
      ...Registration,
      [name]: value,
    })
  }

  const formatName = (name: string): string => {
    return name
      .trim()
      .replace(/\s+/g, ' ') // Replace multiple spaces with a single space
      .toLowerCase()
      .split(' ') // Split words
      .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1)) // Capitalize first letter
      .join(' ') // Join words back
  }

  const handleSubmit = async (e: any) => {
    e.preventDefault()
    // sessionStorage.setItem('storeEmail', Registration.email)
    if (!validateForm()) {
      return
    }
    setLoading(true)
    try {
      // Make an API call here using the formData

      if (Registration.confirmPassword !== Registration.password) {
        // Passwords don't match, show an error message or handle it as needed

        // You can also display an error message to the user
        showSnackbar('Passwords do not match', 'error')
        setLoading(false)
        return // Don't proceed with the API call
      }

      if (Registration.confirmPassword === Registration.password) {
        const formattedFirstName = formatName(firstNameRef.current?.value.trim() ?? '')
        const formattedLastName = formatName(lastNameRef.current?.value.trim() ?? '')
        const response = await fetch(`${baseUrl}/api/auths/register1`, {
          method: 'POST',
          mode: 'cors',
          headers: {
            // 'Origin': 'http://65.20.84.105',
            'Content-Type': 'application/json',
          },

          body: JSON.stringify({
            FirstName: formattedFirstName,
            LastName: formattedLastName,
            PhoneNumber: contactNoRef.current?.value.trim(),
            Password: passwordRef.current?.value.trim(),
            Email: emailRef.current?.value.trim().toLowerCase(),
            state: stateRef.current?.value.trim(),
            city: cityRef.current?.value.trim(),
          }),
        })

        const data = await response.json()

        // data && console.log('response data', data)

        if (data?.status === true) {
          if (
            data?.message ===
            'Phone Number or Email is already registered. Verification OTP sent to your email.'
          ) {
            showSnackbar('Phone Number or Email is already registered.', 'info')
            setLoading(false)
          } else {
            sessionStorage.setItem('userId', data?.user?.id)
            sessionStorage.setItem('email', data?.user?.Email)

            // sessionStorage.setItem('userRegisteredId', data?.user?.id)

            showSnackbar(data?.message, 'success')
            // Handle successful response
            setRegistrationDetails(Registration)
            setActiveStep(1)

            setBackotp(data?.user?.Otp)
            setBackEndOtp(data?.user?.Otp)
            setLoading(false)
          }
        } else {
          // Handle error response
          showSnackbar(data?.message, 'error')
          if (data?.message === 'User email verification is pending.') {
            setActiveStep(1)
            setLoading(false)
          }
          setLoading(false)
          // console.log(data.message)
        }
      } else {
        // Passwords don't match, show an error message or handle it as needed
        // You can also display an error message to the user
        showSnackbar('Registration failed', 'error')
        setLoading(false)
      }
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : String(error)
      showSnackbar('Registration failed, Please try again', 'error')
      setLoading(false)
    }
  }

  // const handleSubmit = (e: any) => {
  //   e.preventDefault()
  //   if (validateForm()) {
  //     const formData = {
  //       firstName: firstNameRef.current?.value.trim() ?? '',
  //       lastName: lastNameRef.current?.value.trim() ?? '',
  //       contactNo: contactNoRef.current?.value.trim() ?? '',
  //       email: emailRef.current?.value.trim() ?? '',
  //       state: stateRef.current?.value.trim() ?? '',
  //       city: cityRef.current?.value.trim() ?? '',
  //       password: passwordRef.current?.value.trim() ?? '',
  //     }
  //     console.log('Form Data Submitted: ', formData)
  //     alert('Form submitted successfully!')
  //   }
  // }

  return (
    <Box
      component='form'
      onSubmit={handleSubmit}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,

        margin: 'auto',
      }}
    >
      <Typography variant='h4' textAlign='center' gutterBottom>
        Registration Form
      </Typography>

      <Grid container spacing={2} sx={{ padding: { xs: '0 2rem', sm: '0 1rem', md: '0 0.5rem' } }}>
        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='firstName'
            autoComplete='off'
            inputRef={firstNameRef}
            label='First Name'
            onBlur={handleChange}
            error={!!errors.firstName}
            helperText={errors.firstName}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='lastName'
            autoComplete='off'
            inputRef={lastNameRef}
            label='Last Name'
            onBlur={handleChange}
            error={!!errors.lastName}
            helperText={errors.lastName}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='contactNo'
            autoComplete='off'
            inputRef={contactNoRef}
            label='Contact No.'
            onBlur={handleChange}
            error={!!errors.contactNo}
            helperText={errors.contactNo}
            fullWidth
            required
            type='tel'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='email'
            autoComplete='off'
            inputRef={emailRef}
            label='Email'
            onBlur={handleChange}
            error={!!errors.email}
            helperText={errors.email}
            fullWidth
            required
            type='email'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='state'
            autoComplete='off'
            inputRef={stateRef}
            label='State'
            onBlur={handleChange}
            select
            error={!!errors.state}
            helperText={errors.state}
            fullWidth
            required
          >
            {states.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='city'
            autoComplete='off'
            inputRef={cityRef}
            label='City'
            onBlur={handleChange}
            error={!!errors.city}
            helperText={errors.city}
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='password'
            autoComplete='off'
            inputRef={passwordRef}
            label='Password'
            onBlur={handleChange}
            error={!!errors.password}
            helperText={errors.password}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={togglePasswordVisibility} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <TextField
            sx={{
              '& .Mui-focused fieldset': {
                border: 'none',
              },
            }}
            name='confirmPassword'
            autoComplete='off'
            inputRef={confirmPasswordRef}
            label='Confirm Password'
            onBlur={handleChange}
            error={!!errors.confirmPassword}
            helperText={errors.confirmPassword}
            type={showPassword ? 'text' : 'password'}
            fullWidth
            required
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton onClick={togglePasswordVisibility} edge='end'>
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>

      {/* Terms & Condition accept */}

      <Box display='flex' alignItems='center' margin='0' sx={{ padding: { xs: '0 20px', sm: 0 } }}>
        <Checkbox {...label} value={termsCheckbox} onClick={handleTermsCheckbox} />
        <Typography>
          Accept
          <Link
            component={RouteLink}
            variant='subtitle2'
            to='/auth/privacy-terms'
            underline='hover'
            color='textSecondary'
            ml={0.5}
          >
            Terms & Conditions
          </Link>
        </Typography>
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          width: '100%',
          padding: { xs: '0 32px', sm: 0 },
          gap: 1,
        }}
      >
        <Box
          display='flex'
          sx={{ width: '100%', justifyContent: 'flex-start', alignItems: 'center' }}
        >
          <Button
            // onClick={() => {
            //   dataSubmit()
            // }}
            disabled={!termsCheckbox} // Disable if termsCheckbox is not checked or form is invalid
            type='submit'
            variant='contained'
          >
            Proceed
          </Button>
          {loading && <CircularProgress sx={{ ml: 1, color: '#353858' }} />}
        </Box>
        <Typography variant='subtitle1'>
          Already a member?
          <Link
            component={RouteLink}
            variant='subtitle2'
            to='/auth/login'
            underline='hover'
            color='textSecondary'
            ml={0.5}
          >
            Login
          </Link>
        </Typography>
      </Box>
    </Box>
  )
}

export default RegistrationForm
